import React from "react";
import styled from "styled-components";
import Bridge from "../../images/northern-spire.png";
import Button from "../button/button";
import Wave from "../../images/wave.svg";
import Wave2 from "../../images/wave2.svg";

const Container = styled.div`
  height: 40rem;
  background-image: url(${Bridge});
  background-position: center;
  background-size: inherit;
  width: 100%;
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  position: relative;
  overflow: hidden;
  margin-bottom: 4rem;

  @media only screen and (min-width: 700px) {
    height: 43rem;
    background-size: cover;
  }

  .container {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    .waves {
      background: url(${Wave2});
      position: absolute;
      top: -198px;
      width: 6400px;
      height: 198px;
      animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
      transform: translate3d(0, 0, 0) translateY(80px);

      @media only screen and (max-width: 700px) {
        transform: translate3d(0, 0, 0) translateY(90px);
      }
    }

    .one {
      @media only screen and (max-width: 700px) {
        transform: translateY(120px);
      }
    }

    .waves:nth-of-type(2) {
      background: url(${Wave});
      top: -175px;
      animation: wave 29s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
        swell 7s ease -1.25s infinite;
      opacity: 1;
    }

    @keyframes wave {
      0% {
        margin-left: 0;
      }
      100% {
        margin-left: -1600px;
      }
    }
  }

  .contents {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
    color: white;

    @media only screen and (max-width: 700px) {
      left: 5%;
      top: 50%;
      transform: translateY(-25%);
    }

    h1 {
      font-size: 2rem;
      margin: 1rem 0;
      font-family: "Poppins";
      line-height: 0.9;
      text-shadow: 3px 3px 6px black;
    }

    @media only screen and (min-width: 700px) {
      h1 {
        font-size: 4rem;
      }
    }
  }
`;

const Banner = () => {
  return (
    <Container>
      <div className="container">
        <div className="waves one"></div>
        <div className="waves"></div>
      </div>
      <div className="contents">
        <h1>HAD AN ACCIDENT?</h1>
        <h1>CAR BROKEDOWN?</h1>
        <h1>WE CAN HELP!</h1>
        <Button telNumber="07497806383"></Button>
      </div>
    </Container>
  );
};

export default Banner;
