import React from "react";
import styled from "styled-components";
import Logo from "../../images/rapid-recovery-logo.png";

const Nav = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  height: 5rem;
  position: fixed;
  z-index: 999;

  .logo {
    height: 4rem;
    margin-left: 10px;
    flex-basis: left;
  }

  .list {
    display: flex;
    list-style: none;
    color: #4b4b4b;
    flex-basis: right;
    padding: 0;
    width: 100%;
    justify-content: flex-end;

    li {
      margin: 0 10px;
      cursor: pointer;

      @media only screen and (max-width: 700px) {
        display: none;
      }
    }

    li:hover {
      color: orange;
      transition: all ease-in-out 0.2s;
    }
  }
`;

function home() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

function myFunction() {
  const element = document.getElementById("segment-one");
  const YOffset = -80;
  const y = element.getBoundingClientRect().top + window.pageYOffset + YOffset;
  window.scrollTo({ top: y, behavior: "smooth" });
}

function myFunctionTwo() {
  const element = document.getElementById("segment-two");
  const YOffset = -65;
  const y = element.getBoundingClientRect().top + window.pageYOffset + YOffset;
  window.scrollTo({ top: y, behavior: "smooth" });
}
const Navigation = () => {
  return (
    <Nav>
      <img onClick={home} className="logo" src={Logo} alt="spire rapid logo" />
      <ul className="list">
        <li onClick={myFunctionTwo}>Breakdown Recovery</li>
        <li onClick={myFunction}>Vehicle Recovery</li>
      </ul>
    </Nav>
  );
};

export default Navigation;
