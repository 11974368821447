import React from "react";
import styled from "styled-components";
import Facebook from "../../images/facebook.svg";
import YellowPages from "../../images/yellowpages.svg";

const FooterContainer = styled.div`
  height: 18rem;
  background: linear-gradient(#e0911a, #e7a33d);
  width: 100%;
  position: relative;
  margin-top: 5rem;
  .contact {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    color: #535353;

    .address {
      text-decoration: none;
      transition: 1s ease-in-out;
      color: #535353;
    }

    .address:hover {
      color: #838383;
    }

    @media only screen and (max-width: 700px) {
      left: 5%;
      font-size: 13px;
    }

    img {
      height: 50px;
      width: 50px;
      margin: 0 5px 0 0;
    }

    h3 {
      margin: 0;
      font-weight: 400;
    }
  }

  .description {
    display: flex;
    position: absolute;
    align-items: center;
    bottom: 10px;
    right: 10px;

    span {
      height: 2rem;
      width: 2px;
      background: white;
    }

    p {
      margin: 0 5px;
      font-weight: 200;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <div className="contact">
        <h3>Spire Rapid Recovery Ltd.</h3>
        <a
          className="address"
          href="https://www.google.com/maps/place/Spire+Rapid+Recovery/@54.9254262,-1.4302256,17z/data=!3m1!4b1!4m5!3m4!1s0x487e65bf350f026f:0x5cc4e06cf87e653f!8m2!3d54.9254262!4d-1.4280369"
        >
          <p>
            78 Rochdale Road <br></br>Redhouse<br></br> Sunderland <br></br>SR5
            5NL
          </p>
        </a>
        <div>
          <a href="https://www.facebook.com/Spire-rapid-recovery-353612278760975/">
            <img src={Facebook} alt="yellow pages external link" />
          </a>
          <a href="https://www.yell.com/biz/spire-rapid-recovery-sunderland-9206995/">
            <img src={YellowPages} alt="yellow pages external link" />
          </a>
        </div>
      </div>
      <div className="description">
        <p>Developed By</p>
        <span></span>
        <p>Kye Atkinson</p>
      </div>
    </FooterContainer>
  );
};

export default Footer;
