import React from "react";
import styled from "styled-components";

const Container = styled.div`
  .telephone {
    background: linear-gradient(to right, #ed9b21, #e7c13d);
    border-radius: 30px;
    width: 200px;
    height: 40px;

    @media only screen and (max-width: 700px) {
      height: 50px;
      font-size: 1.2rem;
    }

    a {
      text-decoration: none;
      color: white;
      width: 100%;
      text-align: center;
      height: 100%;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-weight: 600;
      text-shadow: 2px 2px 5px rgba(150, 150, 150, 1);
    }

    :hover {
      background: linear-gradient(to right, #e98c00, #dbab00);
    }
  }
`;

const Button = ({ telNumber }) => {
  return (
    <Container>
      {telNumber ? (
        <div className="telephone">
          <a href={`tel:${telNumber}`}>Call Us</a>
        </div>
      ) : null}
    </Container>
  );
};

export default Button;
