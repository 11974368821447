import React, { useEffect } from "react";
import Navigation from "./components/navigation/navigation";
import Footer from "./components/footer/footer";
import Banner from "./components/banner/banner";
import ContentHolder from "./components/content-holder/content-holder";
import Delivery from "./images/delivery.jpg";

function App() {
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  });
  return (
    <div className="App">
      <header className="App-header"></header>
      <body>
        <Navigation />
        <Banner />
        <ContentHolder
          id="segment-two"
          title="FAST 24/7 BREAKDOWN RECOVERY"
          text="Don’t worry, we can help. Give us a call and we'll come and pick you
            up. Our team will take your broken-down car to any location of your
            choice, we can also carry a maximum of two passengers. Call us to
            find out how we can help."
        />
        <ContentHolder
          id="segment-one"
          imageSrc={Delivery}
          title="VEHICLE DELIVERY / RECOVERY"
          direction="flip"
          text="At Spire Rapid Recovery, we understand that transporting your vehicle can be difficult. If your car needs to be taken in for a service or repair at a local garage  we can help. Or if it has broken down overnight and been taken home, we will take it away for repair the next day and deliver it back to you. Get in touch with us to discuss your requirements, we will be happy to help you"
        />
      </body>
      <Footer />
    </div>
  );
}

export default App;
