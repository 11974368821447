import React from "react";
import styled from "styled-components";
import Image from "../../images/breakdown.png";

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 12rem;
  max-width: 60rem;
  margin: 2rem auto;
  filter: drop-shadow(2px 3px 15px rgba(0, 0, 0, 0.15));
  z-index: 1;

  @media only screen and (max-width: 700px) {
    width: 100%;
    margin: 1rem 0;
    min-width: 100vw;
    filter: none;
  }
  .box {
    background: white;
    display: flex;
    min-width: 100%;
    min-height: 300px;
    border-radius: 10px;
    position: relative;
    flex-direction: ${(props) => (props.direction ? "row-reverse" : null)};

    @media only screen and (max-width: 700px) {
      width: 97vw;
      padding-bottom: 1rem;
    }

    @media only screen and (max-width: 700px) {
      flex-direction: column;
    }
    .img {
      height: 100%;
      width: auto;
      background-image: url(${(props) =>
        props.images ? props.images : Image});
      background-size: cover;
      border-radius: ${(props) => (!props.direction ? "0 10px 10px 0" : "")};
      flex: 2;

      @media only screen and (max-width: 700px) {
        order: 1;
        width: 100%;
        height: 8rem;
        border-radius: 0;
        flex: auto;
        background-position: center;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      margin: 0 1rem;
      flex: 4;

      p {
        padding-right: 5rem;
        font-weight: 300;
      }

      h2 {
        margin: 20px 0 5px 0;
        width: 20rem;
      }

      .colored-line {
        width: 12rem;
        height: 5px;
        background: linear-gradient(45deg, #e7a33d, #e4de4c);
        border-radius: 5px;
      }

      @media only screen and (max-width: 700px) {
        order: 2;
        flex: auto;
      }
    }
  }
`;

const ContentHolder = ({ id, title, text, direction, imageSrc }) => {
  return (
    <Container direction={direction} images={imageSrc}>
      <div id={id} className="box">
        <div className="content">
          <h2>{title}</h2>
          <span className="colored-line"></span>
          <p>{text}</p>
        </div>
        <div className="img"></div>
      </div>
    </Container>
  );
};

export default ContentHolder;
